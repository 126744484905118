<template>
  <div>
    <div class="contact-main">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div v-if="loading" class="loading-wrap">
              <div class="lds-ellipsis">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <div class="row g-0">
<!--              order-2 order-xl-1 order-lg-1 order-md-2 order-sm-2-->
              <div class="col-1 col-lg-1 col-md-1 col-sm-2 ">
                <div class="mt-100 box-shadow social-main">
                  <ul class="social-icons d-xl-flex d-lg-flex d-md-flex d-sm-flex d-flex">
                    <li class="social-facebook">
                      <Tooltip class="item" effect="dark" content="Facebook" placement="top">
                        <a :href="contact.facebook" target="_blank"  title="Facebook хуудас">
                          <Icon type="logo-facebook" />
                        </a>
                      </Tooltip>
                    </li>
                    <li class="social-twitter">
                      <Tooltip class="item" effect="dark" content="Twitter" placement="top">
                        <a :href="contact.twitter" target="_blank">
                          <Icon type="logo-twitter" />
                        </a>
                      </Tooltip>
                    </li>
                    <li class="social-linkedin">
                      <Tooltip class="item" effect="dark" content="Instagram" placement="top">
                        <a :href="contact.instagram" target="_blank">
                          <Icon type="logo-instagram" />
                        </a>
                      </Tooltip>
                    </li>
                    <li class="social-youtube">
                      <Tooltip class="item" effect="dark" content="Youtube" placement="top">
                        <a href="" target="_blank">
                          <Icon type="logo-youtube" /></a>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
              </div>
<!--              order-1 order-xl-3 order-lg-3-->
              <div class="col-11 col-lg-11 col-md-11 col-sm-10 box-shadow">
                <div class="mt-100 box-shadow contact-map">
                  <div class="contact-map-layer">
                    <template>
                      <h3>{{ 'location_map' | translate }}</h3>
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1819.350766334726!2d106.91299348764073!3d47.924112465932694!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x5d96925d321097cd%3A0x74318191bfddb20e!2sMinistry%20of%20Labour%20and%20Social%20Protection%20of%20Mongolia!5e1!3m2!1sen!2smn!4v1637301552122!5m2!1sen!2smn" width="600" height="450"  loading="lazy"></iframe>
                    </template>
                  </div>
                  <div class="row">
                    <div class="col-12 col-md-6">
                      <div class="contact-info-container">
                        <div class="icon-style-border-contact">
                          <Icon type="ios-mail-outline" />
                        </div>

                        <div class="title-box-contact">
                          <h5>{{ 'email' | translate }}</h5>
                          <p><a :href="contact.web" target="_blank">{{ contact.web }}</a></p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="contact-info-container">
                        <div class="icon-style-border-contact">
                          <Icon type="ios-map-outline" />
                        </div>
                        <div class="title-box-contact">
                          <h5>{{ 'addrees_location' | translate }}</h5>
                          <p class="address" v-if="language.short == 'mn'">{{ contact.address }}</p>
                          <p class="address" v-else>{{ contact.address_en }}</p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="contact-info-container">
                        <div class="icon-style-border-contact">
                          <Icon type="ios-call-outline" />
                        </div>
                        <div class="title-box-contact">
                          <h5>{{ 'phone' | translate }}</h5>
                          <p>
                            <a href="" v-if="language.short == 'mn'">{{ contact.phone_one }}</a>
                            <a href="" v-else>{{ contact.phone_one_en }}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 col-md-6">
                      <div class="contact-info-container">
                        <div class="icon-style-border-contact">
                          <Icon type="ios-call-outline" />
                        </div>
                        <div class="title-box-contact">
                          <h5>{{ 'phone' | translate }}</h5>
                          <p>
                            <a href="" v-if="language.short == 'mn'">{{ contact.phone_two }}</a>
                            <a href="" v-else>{{ contact.phone_two_en }}</a>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script >
import { GET_CONTACT } from '../graphql/queries'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      contact: {},
      loading: true
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      // ...
    ]),
  },
  mounted () {
    this.getContact()
  },
  methods: {
    getContact () {
      this.loading = true
      this.$apollo.query({ query: GET_CONTACT }).then(({ data }) => {
        this.contact = data.contact[0]
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    }
  }
}
</script>
