<template>
  <div id="auth" class="container-fluid">
      <div class="row">
        <div class="col-md-6">
          <div class="auth-left">

            <div class="row">
              <div class="col-12">
                <img class="yam_logo" src="../assets/yam_logo.svg" alt="" v-if="language.short == 'mn'">
                <img class="yam_logo" src="../assets/yam_logo_eng.svg" alt="" v-else>
                <p class="fst_title">{{ 'actual_survey' | translate }}
                  <br>
                  <b>{{ 'optimal' | translate }}</b>
                </p>
              </div>
              <div class="col-12">
                <img src="../assets/img/home.svg" class="mission_logo" alt="">
              </div>
              <div class="col-12">
                <p class="phone_number footer_txt">{{ 'contact' | translate }}
                  <br>
                  <b> {{ contact.phone_one }}</b>
                </p>
                <p class="email_txt footer_txt">
                  {{ 'email' | translate }}
                  <br>
                  <b>{{ contact.web }}</b>
                </p>
              </div>
            </div>

          </div>
        </div>
        <div class="col-md-6">
          <div class="auth-right">
            <router-link to="/" class="_back">{{ 'back' | translate }}</router-link>
            <div class="auth-form box-shadow">
              <div class="auth-form-layer">

                <Tabs value="name1" v-model="tab" closable>
                  <TabPane label="Нэвтрэх" name="login">
                    <Form ref="login" :model="loginForm" :rules="loginRule" v-if="tab == 'login'">
                      <FormItem prop="login" label="Нэвтрэх нэр">
                        <Input type="text" v-model="loginForm.login" placeholder="Нэвтрэх нэр"
                               @keyup.enter.native="handleSubmit('login')">
                          <Icon type="ios-person-outline" slot="prepend"></Icon>
                        </Input>
                      </FormItem>
                      <FormItem prop="password" label="Нууц үг">
                        <Input type="password" v-model="loginForm.password" placeholder="Нууц үг"
                               @keyup.enter.native="handleSubmit('login')">
                          <Icon type="ios-lock-outline" slot="prepend"></Icon>
                        </Input>
                        <a href="3" class="forgot">Нууц үгээ мартсан уу?</a>
                      </FormItem>
                      <FormItem>
                        <Button type="primary" class="login_btn" @click="login('login')">
                          Нэвтрэх
                        </Button>
                      </FormItem>
                      <Alert type="success" v-if="isSuccess" show-icon>
                        Амжилттай нэвтэрлээ. Түр хүлээнэ үү!
                      </Alert>
                      <Alert type="error" show-icon v-if="isError">
                        Нэвтрэх явцад алдаа гарлаа. Нэвтрэх мэдээллээ шалгаад дахин оролдоно уу?
                      </Alert>
                    </Form>
                  </TabPane>
                  <TabPane label="Бүртгүүлэх" name="register">
                     <div v-if="tab == 'register'">
                       <div>
                         <dataform ref="register-form"  :public="true" schemaID="345" :onSuccess="onSuccess" :onError="onError"/>
                       </div>
                     </div>
                  </TabPane>
                </Tabs>
<!--                <div class="language">-->
<!--                  <ul>-->
<!--                    <li v-for="locale in languages" :key="locale.index"-->
<!--                        v-show="locale.short != language.short" @click="changeLocale(locale)">-->
<!--                      <a >{{ locale.long }}</a>-->
<!--                    </li>-->
<!--                  </ul>-->
<!--                </div>-->

              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { GET_CONTACT } from '../graphql/queries'
import Vue from 'vue'

const Dataform = () => import(/* webpackChunkName: "Dataform-el" */'@lambda-platform/dataform/src/Dataform.vue')

export default {
  name: 'login',
  data () {
    return {
      contact: {},
      loginRegisterShow: false,
      tab: 'login',
      isSuccess: false,
      isError: false,
      loading: true,
      loginForm: {
        login: '',
        password: ''
      },
    }
  },
  components: {
    "dataform": Dataform,
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      'userToken',
      // ...
    ]),

    ruleValidate(){
      return{
        name: [
          {required: true, message: this.$t('name_cannot_empty'), trigger: 'blur'}
        ]
      }
    },
    loginRule(){
      return{
        login: [
          {required: true, message: this.$t('Цахим хаяг аа оруулна уу!'), trigger: 'blur'}
        ],
        password: [
          {required: true, message: this.$t('Нууц үг оруулна уу!'), trigger: 'blur'},
          // { type: 'string', min: 6, message: 'The password length cannot be less than 6 bits', trigger: 'blur' }
        ]
      }
    },
  },
  mounted () {
    this.getContact()
  },
  methods: {
    changeLocale(locale) {
      Vue.i18n.set(locale.short);
      this.$store.commit('setLanguage', locale);
    },
    getContact () {
      this.loading = true
      this.$apollo.query({ query: GET_CONTACT }).then(({ data }) => {
        this.contact = data.contact[0]
        setTimeout(() => {
          this.loading = false
        }, 1000)
      })
    },
    onSuccess() {
      this.$Notice.success({
        title: this.$t("Амжилттай хадгалагдлаа"),
        desc: this.$t("Таны бүртгэл амжилттай үүсгэгдсэн")
      });
    },
    onError() {
      this.$Notice.error({
        title: this.$t("Aлдаа"),
        desc: this.$t("Илгээхэд алдаа гарлаа")
      });
    },
    login(formRef) {
      this.$refs[formRef].validate((valid) => {
        if (valid) {
          this.isSuccess = false;
          this.isError = false;
          this.$http.post('/auth/login', this.loginForm).then(({data}) => {
            if (data.status) {
              this.loading = false;
              this.isError = false;
              this.isSuccess = true;
              this.msg = data.msg;
              this.$store.commit('setUser', data.data);
              this.$store.commit('setUserToken', data.token);
              setTimeout(() => {
                  this.$router.push("/")
                  // window.location.reload();
              }, 1000);
            } else {
              this.$store.commit('setUser', null);
              this.$store.commit('setUserToken', null);
              this.loading = false;
              this.isError = true;
              this.msg = this.$t("Нэвтрэх мэдээлэл буруу байна");
              // }, 1000);
            }
            // eslint-disable-next-line no-unused-vars
          }).catch(e => {
            setTimeout(() => {
              this.$store.commit('setUser', null);
              this.$store.commit('setUserToken', null);
              this.loading = false;
              this.isError = true;
              this.isSuccess = false;
              this.msg = this.$t("Нэвтрэх мэдээлэл буруу байна");
            }, 1000);
          })
        }
      })
    },
  }
}
</script>
