<template>
  <div class="list">
    <div class="container">
      <div class="row" v-if="surveys.length > 0">

        <div v-if="loading" class="loading-wrap">
          <div class="lds-ellipsis">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>

        <div class="col-md-4" :key="index" v-for="(survey, index) in surveys">
          <div class="research-item">
            <div class="right-btn">
              <Tooltip placement="top" :content="survey.status">
                <div class="circle_container" shape="circle"><Button class="q_btn" shape="circle" icon="md-help"></Button></div>
              </Tooltip>
            </div>
<!--            <img src="../assets/yam_logo.svg" class="l-light" height="54" alt="" v-if="language.short == 'mn'">-->
<!--            <img src="../assets/yam_logo_eng.svg" class="l-light" height="54" alt="" v-else>-->
            <div class="title-bg" v-if="language.short == 'mn'">{{ survey.title }}</div>
            <div class="title-bg" v-else>{{ survey.title_en }}</div>
            <div class="_desc">
              <span v-if="language.short == 'mn'">{{ survey.description }}</span>
              <span v-else>{{ survey.description_en }}</span>
            </div>

            <div class="_group-btn">
                <Button class="btn-color-primary" @click="goToResearch(survey.id)" v-if="canShowResult(survey.id)">Дахин өгөх</Button>
                <Button class="btn-color-primary" @click="goToResearch(survey.id)" v-else>{{ 'fill_survey' | translate }}</Button>
                <Button  class="btn-color-secondary" @click="goToResearchView(survey.id)" v-if="canShowResult(survey.id)">Судалгаа харах</Button>
            </div>

<!--            <div class="_group-btn">-->
<!--              <Button  class="btn-color-secondary" @click="goToResearchView(survey.id)" v-if="canShowResult(survey.id)">Судалгаа харах</Button>-->
<!--            </div>-->

          </div>
        </div>
      </div>
      <div class="row" v-else>
        <div class='research-left' style="text-align: center; padding: 80px 10px;display: block">
          <i class="ivu-icon ivu-icon-md-alert" style="font-size: 36px; color: #217BF4;"></i>
          <div style="margin: 20px 0">Судалгаа оруулаагүй байна !!!</div>
        </div>
      </div>
      <div class="row">
            <Page @on-change="changePage"
                  :current-page="current_page"
                  :page-size="9"
                  :total="total"
            />
      </div>
    </div>
  </div>
</template>
<script>
import { GET_SURVEY_PAGE, GET_SURVEY_RESULTs_BY_ID } from '../graphql/queries'
import { mapGetters } from 'vuex'

export default {
  name: 'Home',
  data () {
    return {
      surveys: [],
      loading: true,
      total: 0,
      current_page: 1,
      last_page: 1,
      userSurveyResults:[],
    }
  },
  computed: {
    ...mapGetters([
      'languages',
      'language',
      'user',
      // ...
    ]),
  },
  mounted () {
    this.getSURVEY();
  },
  methods: {
    canShowResult(survey_id){
      if(this.userSurveyResults.length >= 1){
        let index = this.userSurveyResults.findIndex(result=>result.survey_id == survey_id);
        if(index >= 0){
          return true;
        } else {
          return  false;
        }
      } else {
        return false;
      }
    },
    getSURVEY (){
      this.loading = true
      this.$apollo.query({
        query: GET_SURVEY_PAGE,
        variables: { page: this.current_page, size: 9, status_id: "2" }
      }).then(({ data }) => {
        this.surveys = data.paginate.ds_view_survey
        this.total = data.paginate.total;
        //console.log(this.surveys)
        this.userResults();
        setTimeout(() => {
          this.loading = false
        });
      })
    },
    userResults(){
      if(this.user){
        if(this.user.id){
          this.$apollo.query({ query: GET_SURVEY_RESULTs_BY_ID,
            variables: {
              user_id: this.user.id,
            }
          }).then(({data}) => {
            this.userSurveyResults =  data.survey_result;
          });
        }
      }
    },
    changePage (page) {
      this.current_page = page
      this.getSURVEY()
    },
    // eslint-disable-next-line camelcase
    goToResearch (survey_id) {
      // eslint-disable-next-line camelcase
      this.$router.push('/form-survey/' + survey_id)
    },
    goToResearchView (survey_id) {
      // eslint-disable-next-line camelcase
      this.$router.push('/survey-view/' + survey_id)
    }
  }
}
</script>
